/*=========================================
	++ common.js ++ Ver.1.6.1
	Last update 2020.9.10
==========================================*/
/*
 * 2020  9/10 全体を一新
 * 2019  5/ 7  *object-fit-images 追加
 * 2018 11/20  *ブラウザ判定処理を一新
 * 2018 10/25  *jQuery 3系 に対応
 */

'usestrict';


//=====================================
//	Detecting Browser & Device Ver.1.4.2
//============================================

//++++++++++++ モバイル/タブレット/ブラウザ判定処理 ++++++++++++

//ブラウザ/デバイス 種別
var isMobile = false,
	isTablet = false,
	isRetina = false,
	isMobileTablet = false,
	isAndroid = false;

//モバイル　タブレット　判定
//https://w3g.jp/blog/js_browser_sniffing2015
var _ua = (function (u) {
	return {
		Tablet: (u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1) ||
			u.indexOf("ipad") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") == -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1) ||
			u.indexOf("kindle") != -1 ||
			u.indexOf("silk") != -1 ||
			u.indexOf("playbook") != -1,
		Mobile: (u.indexOf("windows") != -1 && u.indexOf("phone") != -1) ||
			u.indexOf("iphone") != -1 ||
			u.indexOf("ipod") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") != -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1) |
			u.indexOf("blackberry") != -1
	};
})(window.navigator.userAgent.toLowerCase());

//モバイル
if (_ua.Mobile) {
	isMobile = true;
	isMobileTablet = true;
}
//タブレット
if (_ua.Tablet) {
	isTablet = true;
	isMobileTablet = true;
}

//Retina Display 判定
if (window.retina || window.devicePixelRatio > 1) {
	isRetina = true;
}

//Android 判定
if (/android[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
	isAndroid = true;
}


//============================================
//	デバイス　ブラウザ別の処理
//============================================
//++++++++++++ モバイル/タブレット クラス追加 ++++++++++++

$(function () {
	if (isMobile) {
		//HTMLにclass isMobileを追加
		$('html').addClass('isMobile');
		$('html').addClass('isMobileTablet');
	} else if (isTablet) {
		//HTMLにclass isTabletを追加
		$('html').addClass('isTablet');
		$('html').addClass('isMobileTablet');
	}
	//Retina Display isRetina クラス追加
	if (isRetina) {
		$('html').addClass('isRetina');
	}
});





//============================================
//	汎用スクリプト 
//============================================
//++++++ Copyright ++++++
// Copyright <span class="copyYear">&copy;</span>

$(function () {
	const date = new Date();
	const copyYear = date.getFullYear();
	copyYear = '&copy;' + copyYear;
	$('.copyYear').html(copyYear);
});



$(function () {
	$(".qa_list dd").hide();
	$(".qa_list dl").on("click", function (e) {
		$('dd', this).slideToggle('fast');
		if ($(this).hasClass('open')) {
			$(this).removeClass('open');
		} else {
			$(this).addClass('open');
		}
	});
});

$(function () {
	$('.venobox').venobox({
		infinigall: true,
		numeratio: true,
	});
});



$(function () {
	$(".menubtn").click(function () {
		$(this).toggleClass('active');
		$('body').toggleClass('no-scroll');
		$("#spnav").toggleClass('panelactive');
	});

	$("#spnav a").click(function () {
		$(".menubtn").removeClass('active');
		$('body').removeClass('no-scroll');
		$("#spnav").removeClass('panelactive');
	});
})


$(function () {
	$(".snav_cat a").click(function () {
		$(this).toggleClass('active');
		$('.snav_sub').toggleClass('show');
	});


})



//========= 検索 ================

$(function () {
	$('.search_tab_item').on('click', function () {
		const checkId = $(this).attr('id');

		$('.search_tab_item').removeClass('selected');
		$(this).addClass('selected');

		if ($('.search_cat_list').hasClass(checkId)) {
			// console.log('OK');
			$('.search_cat_list').removeClass('show');
			$(`.search_cat_list.${checkId}`).addClass('show');
		}
	});
});




//検索カテゴリ切り替え
$(function () {
	$('.js-type-toggle-btn').on('click', function () {
		$('.togle_SearchType').removeClass('toggle_show ');
		const target = $(this).data('target');
		console.log(target)

		$('.js-type-toggle-btn').removeClass('selected');
		$(this).addClass('selected');

		$('.togle_SearchType').fadeOut(0);
		$(target).fadeIn('slow');


	});
});



$(function () {
	var $grid = $('.grid'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.cell').length; i++) {
		emptyCells.push($('<div>', {
			class: 'cell is-empty'
		}));
	}
	$grid.append(emptyCells);
});






$(function () {
	var $grid = $('.square_column'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.square_card').length; i++) {
		emptyCells.push($('<div>', {
			class: 'square_card is-empty'
		}));
	}
	$grid.append(emptyCells);
});


$(function () {
	var $grid = $('.entry_column'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.entry_title_only').length; i++) {
		emptyCells.push($('<div>', {
			class: 'entry_title_only is-empty'
		}));
	}
	$grid.append(emptyCells);
});

$(function () {
	var $grid = $('.commun_column'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.commun_card').length; i++) {
		emptyCells.push($('<div>', {
			class: 'commun_card is-empty'
		}));
	}
	$grid.append(emptyCells);
});





$(function () {
	if ($("#ticker-wrap").length) {
		const a = $(".pickup_summary");
		const r = $(".news_ticker_list_item:first-child", a);
		r.addClass("is-active");
		setInterval(function () {
			if ($(".is-active", a).next().length > 0) {
				$(".is-active", a).next().addClass("is-active").end().removeClass("is-active").appendTo(a);
			}
		}, 5000);
	}
});












$(function () {

	/* ここには、表示するリストの数を指定します。 */
	var moreNum = 4;

	/* 表示するリストの数以降のリストを隠しておきます。 */
	$('.entry_card:nth-child(n + ' + (moreNum + 1) + ')').addClass('is-hidden');

	/* 全てのリストを表示したら「もっとみる」ボタンをフェードアウトします。 */
	$('.list-btn').on('click', function () {
		$('.entry_card.is-hidden').slice(0, moreNum).removeClass('is-hidden');
		if ($('.entry_card.is-hidden').length == 0) {
			$('.list-btn').fadeOut();
		}
	});

	/* リストの数が、表示するリストの数以下だった場合、「もっとみる」ボタンを非表示にします。 */
	$(function () {
		var list = $(".entry_card").length;
		if (list < moreNum) {
			$('.list-btn').addClass('is-btn-hidden');
		}
	});
});